import {Check} from './check';
import {Order} from '../order';

export interface Card {
    id: string;
    label: string;
    status: PrepStatus;
    properties?: { [key: string]: string };
    createdAt: number;
    updatedAt: number;
}

export type PrepStatus =
    | 'preparing'
    | 'ready'
    | 'delivered'
    | 'canceled'
    | 'unknown';

export const cardConverter = {
    fromCheck: (check: Check): Card => {
        /* eslint-disable @typescript-eslint/naming-convention */
        const status: { [key: string]: PrepStatus } = {
            PREPARING: 'preparing',
            READY: 'ready',
            DELIVERED: 'delivered',
        };
        /* eslint-enable @typescript-eslint/naming-convention */

        return {
            id: check.id,
            label: check.label,
            status: status[check.prepStatus] || 'unknown',
            createdAt: check.createdAt.toMillis(),
            updatedAt: check.updatedAt.toMillis(),
            properties: {
                source: 'orb-collector',
                ...JSON.parse(check.properties),
            },
        };
    },

    fromPejOrder: (order: Order): Card => {
        return {
            id: order.id.toString(),
            label: order.verification.toString(),
            status: getCardStatus(order.status),
            createdAt: order.timeCreated,
            updatedAt: order.timeUpdated,
            properties: {
                source: 'pej',
                rvc: order.metadata?.simphonyRevenueCenterObjectNum,
                kdsStatus: order.metadata?.kdsStatus,                
            },
        };
    },
};

function getCardStatus(pejOrderStatus: string): PrepStatus {
    switch (pejOrderStatus) {
        case 'wait_packaging':
            return 'preparing';
        case 'wait_delivery_pickup':
        case 'packaged':
            return 'ready';
        default:
            return 'unknown';
    }
}
