import { FirestoreObjectStringId } from '../shared';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

export class Check implements FirestoreObjectStringId {
    id: string;
    fromCache?: boolean;
    label: string;
    properties: string;
    prepStatus: string;

    createdAt: Timestamp;
    updatedAt: Timestamp;
}
